<template>
  <v-expansion-panel-content>
    <v-row>
      <v-col cols="3">
        <label class="subtitle">Host*</label>
        <v-text-field v-model="server.host" :disabled="!editable" outlined hide-details />
      </v-col>
      <v-col cols="3">
        <label class="subtitle">Porta*</label>
        <v-text-field v-model="server.port" :disabled="!editable" outlined hide-details />
      </v-col>
      <v-col cols="3">
        <label class="subtitle">Usuário*</label>
        <v-text-field v-model="server.user" :disabled="!editable" outlined hide-details />
      </v-col>
      <v-col cols="3">
        <label class="subtitle">Senha*</label>
        <v-text-field v-model="server.password" :disabled="!editable" outlined hide-details />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <label class="subtitle">Database*</label>
        <v-text-field v-model="server.database_name" :disabled="!editable" outlined hide-details />
      </v-col>
    </v-row>
    <v-row>
      <btn-card-actions class="py-2 pl-3 mx-0" :editable.sync="editable" @save="saveEditServer" @cancel="cancelAndRestoreData" />
      <v-btn v-if="editable" color="error" class="my-4 mx-0" @click="removeServer"><v-icon class="mr-1">mdi-alert-circle</v-icon> Remover este servidor</v-btn>
    </v-row>
  </v-expansion-panel-content>
</template>

<script>
import { MUTATION_EDIT_SKAAR_SERVER, MUTATION_REMOVE_SKAAR_SERVER } from '@/modules/accounts/graphql'

export default {
  name: 'HotsiteServers',
  components: {
    BtnCardActions: () => import('@/components/base/BtnCardActions')
  },
  props: {
    accountId: {
      type: String,
      default: ''
    },
    server: {
      type: Object,
      default: () => ({})
    },
    index: {
      type: Number,
      default: 0
    }
  },
  data: () => ({
    editable: false
  }),
  methods: {
    async saveEditServer() {
      try {
        await this.$apollo.mutate({
          mutation: MUTATION_EDIT_SKAAR_SERVER,
          variables: {
            id: this.accountId,
            index: this.index,
            host: this.server.host,
            port: this.server.port,
            user: this.server.user,
            password: this.server.password,
            database_name: this.server.database_name
          }
        })
        this.$emit('update:server', this.server)
        this.cancelAndRestoreData()
        this.$snackbar({ message: 'Servidor atualizado com sucesso', snackbarColor: '#2E7D32' })
      } catch (err) {
        console.log(err.message)
        this.$snackbar({ message: `Falha ao editar servidor "${this.server.database_name}"`, snackbarColor: '#F44336' })
      }
    },
    async removeServer() {
      try {
        await this.$apollo.mutate({
          mutation: MUTATION_REMOVE_SKAAR_SERVER,
          variables: {
            id: this.accountId,
            index: this.index
          },
          context: {
            uri: this.$microservicesUrls['hulk']
          }
        })
        this.$emit('refresh')
        this.$snackbar({ message: 'Servidor removido com sucesso', snackbarColor: '#2E7D32' })
      } catch (err) {
        console.log(err.message)
        this.$snackbar({ message: 'Falha ao remover servidor', snackbarColor: '#F44336' })
      }
    },
    cancelAndRestoreData() {
      this.editable = false
      this.$emit('refresh')
    }
  }
}
</script>
